import logo from './elephant-svgrepo-com.svg';
import logo2 from './traffic-cone-svgrepo-com.svg';
import React from 'react';
import '../Styles.css';


function Home() {
  return (
    <div>
      <header className="Home-header">
        <p>
        Belfast Indian Catering<br></br>
        <span class="centered-text">Company</span>
        </p>
        <img src={logo} className="App-logo-elephant" alt="logo" />
      </header>
      <h1 className="Home-header1">
        <p>
          (Site under construction)
        </p>
        <img src={logo2} className="App-logo" alt="logo2" />
      </h1>
    </div>
  );
};

export default Home;