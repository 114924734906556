import logo from './elephant-svgrepo-com.svg';
import React from 'react';
import '../Styles.css';


const Pricing = () => {
    return (
        <div>
            <header className='Pricing-header'>
                <p>
                    Pricing
                </p>
                <img src={logo} className="App-logo-elephant" alt="logo" />
            </header>
            <h1 className="Pricing-header1">
                <p>
                    (Pricing details to go here)
                </p>
            </h1>
        </div>
    );
};

export default Pricing;