import logo from './elephant-svgrepo-com.svg';
import React from 'react';
import '../Styles.css';

const ContactUs = () => {
  return (
    <div>
      <header className='ContactUs-header'>
        <p>
          Contact Us
        </p>
        <img src={logo} className="App-logo-elephant" alt="logo" />
      </header>
      <h1 className="ContactUs-header1">
        <p>
          (Contact Form to go here)
        </p>
      </h1>
    </div>
  );
};

export default ContactUs;